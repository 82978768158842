import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import BatchLoadPanel from 'src/components/batch-load';
import { BatchCardPreview } from 'src/components/BatchCardPreview';
import Header from "src/components/header";
import Loader from "src/components/loader";
import LocationsMismatchWarning from 'src/components/LocationsMismatchWarning';
import NotFound from "src/components/not-found";
import PartialLoadPanel from 'src/components/partial-load';
import { MHS_MODULES, MHS_MODULES_MAP, MLINE_MODULE_TYPES, MODULE_TYPES_MAP } from "src/constants/m-line";
import { useBatch } from 'src/hooks/services/useBatch';
import { useLocation, useSubLocation } from 'src/hooks/services/useLocation';
import { useMaterialsByUri } from 'src/hooks/services/useMaterials';
import {
  useBatchTransactionByBatchUriAndStatus,
  useCyclone, useCycloneBatch,
  useMhsInputModule,
  useMhsOutputModule,
  useMlineMHSByPostProcessorUri,
} from 'src/hooks/services/useMhs';
import { usePostProcessor, usePostProcessorType } from 'src/hooks/services/usePostProcessor';
import BuildTrustCycloneForm from 'src/pages/mline/sections/mhs/build-trust-cyclone-form';
import CycloneTrustNotification from 'src/pages/mline/sections/mhs/cyclone-trust-notification';
import GeneralTrustCycloneForm from 'src/pages/mline/sections/mhs/general-trust-cyclone-form';
import MHSActions from "src/pages/mline/sections/mhs/mhs-actions";
import MhsCard from "src/pages/mline/sections/mhs/mhs-card";
import MHSModulesContainer from "src/pages/mline/sections/mhs/modules/mhs-module-container";
import useActionPanelStore from "src/stores/useActionPanelStore";
import useScanStore from "src/stores/useScanStore";
import { relocateContainerAction,
  triggerMhsModuleConnection,
} from 'src/utils/actionsAPIUtils';
import {
  api,
  fetchBatchValidContainers,
  fetchContainerOrMhsModuleData,
  fetchContainerRelatedBatch,
  fetchMaterialData,
  getBuildTransactionsBatchActions,
} from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  PERMANENT_CONTAINER_ACTIONS,
  PERMANENT_CONTAINER_TYPES_VERBOSE,
} from 'src/utils/constants';
import { checkIfModuleAlreadyDocked } from 'src/utils/mlineUtils';
import { pluralWord } from 'src/utils/stringUtils';
import { getUuid } from 'src/utils/url';
import userPropType from "src/utils/user-prop-type";
import { checkSupportedMaterialsMismatch, hasLocationsMismatch } from 'src/utils/validation';

const MLineMHSPage = ({ user }) => {
  const { uuid: postProcessorUUID } = useParams();
  const didRelocateRef = useRef(false);
  const [batchState, setBatchState] = useState({
    batch: null,
    allContainers: [],
    containersFetching: true,
    containersFetchError: null,
    loadedPrinter: null,
    loadedPostProcessor: null,
    subLocation: null,
  });

  const { addToast } = useToasts();

  const { openActionPanel, closeActionPanel, closeAllActionPanels, setActionPanelProps } = useActionPanelStore();
  const { triggerScanError } =
    useScanStore();

  const {
    data: postProcessor,
    isLoading: isMachineLoading,
    error: machineError,
  } = usePostProcessor(postProcessorUUID);

  const postProcessorTypeUri = postProcessor?.post_processor_type;

  const { data: postProcessorType, isInitialLoading: postProcessorTypeLoading } = usePostProcessorType(postProcessorTypeUri);

  const allowedMaterialsUris = postProcessorType?.materials;

  const { data: allowedMaterials, isInitialLoading: allowedMaterialsLoading } = useMaterialsByUri(allowedMaterialsUris);

  const postProcessorUri = postProcessor?.uri;

  const {
    data: mlineMhs,
    refetch: refetchMhsModule,
    isInitialLoading: mhsLoading,
  } = useMlineMHSByPostProcessorUri(postProcessorUri);

  const { data: mhsLocation, isInitialLoading: isMhsLocationLoading } = useLocation(postProcessor?.location, 'lps-location');
  const { data: mhsSubLocation, isInitialLoading: isMhsSubLocationLoading } = useSubLocation(postProcessor?.sub_location, 'lps-sub-location');

  // 2) Extract URIs for the rest of the data
  const cycloneUri = mlineMhs?.cyclone;
  const inputModuleUri = mlineMhs?.input_module;
  const outputModuleUri = mlineMhs?.output_module;

  // 3) Use separate queries for each resource
  const { data: mhsCyclone, isInitialLoading: cycloneLoading } = useCyclone(cycloneUri);
  const { data: mhsCycloneBatch, isInitialLoading: cycloneBatchLoading } = useCycloneBatch(mhsCyclone?.current_batch);

  const { data: mhsInputModule, refetch: refetchInputModule, isInitialLoading: isInputModuleLoading } = useMhsInputModule(inputModuleUri);
  const { data: mhsOutputModule, refetch: refetchOutputModule, isInitialLoading: isOutputModuleLoading } = useMhsOutputModule(outputModuleUri);

  const inputModuleBatchUri = mhsInputModule?.current_batch;
  const outputModuleBatchUri = mhsOutputModule?.current_batch;
  const { data: inputModuleBatch, isInitialLoading: inputModuleBatchLoading, refetch: refetchInputModuleBatch } = useBatch(inputModuleBatchUri, 'mhs-input-module-batch');
  const { data: outputModuleBatch, isInitialLoading: outputModuleBatchLoading } = useBatch(outputModuleBatchUri, 'mhs-output-module-batch');

  const {
    data: inputModuleTransactions,
    refetch: refetchInputModuleTransactions,
    isLoading: transactionsLoading,
  } = useBatchTransactionByBatchUriAndStatus(mhsInputModule?.current_batch, false);

  const mhsModules = useMemo(() => ([
    { slot: MHS_MODULES.OUTPUT, module: mhsOutputModule },
    { slot: MHS_MODULES.INPUT, module: mhsInputModule },
  ]), [mhsInputModule, mhsOutputModule]);

  const onActionRelocate = async (resourceToRelocate, resourceToRelocateBatchUri) => {
    try {
      const locationToRelocate = postProcessor.location;
      const subLocationToRelocate = postProcessor.sub_location;

      await relocateContainerAction(
        locationToRelocate,
        subLocationToRelocate,
        resourceToRelocate,
        resourceToRelocateBatchUri
      );
      closeActionPanel();
    } catch (error_) {
      console.error(error_);
      throw error_;
    }
  };


  const handleDockModule = async (action, slotPosition, mhsModule) => {
    await triggerMhsModuleConnection(
      action,
      mlineMhs.uri,
      slotPosition,
      mhsModule.current_batch,
      mhsModule.uri
    );
    addToast(`${MHS_MODULES_MAP[slotPosition]} has successfully been docked`, { appearance: 'success' });
    await refetchMhsModule();

    // Only refetch if the corresponding URI exists
    if (slotPosition === MHS_MODULES.INPUT && inputModuleUri) {
      await refetchInputModule();
    } else if (slotPosition === MHS_MODULES.OUTPUT && outputModuleUri) {
      await refetchOutputModule();
    }
    closeActionPanel();
  }

  const onConfirmRelocate = async (action, slotPosition, mhsModule, skipRelocate) => {
    try {
      if (!skipRelocate) {
        await onActionRelocate(mhsModule.uri, mhsModule.current_batch);
      }
      closeAllActionPanels();
      // Mark relocation as done immediately
      didRelocateRef.current = true;
      // Now re-run main confirm
      await handleDockModule(action, slotPosition, mhsModule);
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
    }
  };

  const handleConnectModule = useCallback(async (action, moduleUri, slotPosition) => {
    const mhsModule = await fetchContainerOrMhsModuleData(moduleUri);
    const isModuleEmpty = _isEmpty(mhsModule.current_batch);

    if (!mhsModule) {
      return triggerScanError('The scanned module is not a valid MHS module');
    }

    if (!Object.values(MLINE_MODULE_TYPES).includes(mhsModule.type)) {
      // The scanned module is not a valid MHS module
      return triggerScanError(`The scanned module is a '${PERMANENT_CONTAINER_TYPES_VERBOSE[mhsModule.type]}'. You can only dock Dose, Build, or Overflow modules to the MHS machine.`)
    }

    if (slotPosition === MHS_MODULES.OUTPUT && mhsModule.type !== MLINE_MODULE_TYPES.DOSE) {
      // The scanned module is not a valid MHS module
      return triggerScanError(`The Sieve Side slot only accepts Dose module. You tried to dock ${MODULE_TYPES_MAP[mhsModule.type]} module.`)
    }

    if (slotPosition === MHS_MODULES.INPUT && isModuleEmpty) {
      // Module scanned for Vacuum Side must be loaded.
      return triggerScanError(`The scanned module is empty. Please load the module before docking.`);
    }

    if (slotPosition === MHS_MODULES.INPUT
      && mhsOutputModule
      && mhsOutputModule.uri === mhsModule.uri
    ) {
      // Same module is already docked to Output
      return triggerScanError(`Module ${mhsModule.name} is already docked to the Sieve Side.`);
    }

    if (slotPosition === MHS_MODULES.OUTPUT
      && mhsInputModule
      && mhsInputModule.uri === mhsModule.uri
    ) {
      // Same module is already docked to Input
      return triggerScanError(`Module ${mhsModule.name} is already docked to the Vacuum Side.`);
    }

    const alreadyDockedMessage = await checkIfModuleAlreadyDocked(mhsModule, "MHS", postProcessor.name);

    if (alreadyDockedMessage) {
      return triggerScanError(alreadyDockedMessage)
    }

    const isCycloneLoaded = !!mhsCyclone.material;
    const isModuleLoaded = !!mhsModule.material;
    const moduleHasBatch = !!mhsModule.current_batch;

    const cycloneMaterial = isCycloneLoaded ? await fetchMaterialData(mhsCyclone.material) : null;
    const moduleMaterial = isModuleLoaded ? await fetchMaterialData(mhsModule.material) : null;

    if (((isCycloneLoaded && isModuleLoaded) && (cycloneMaterial?.name !== moduleMaterial?.name))) {
      // Material mismatch
      if (!isModuleEmpty) {
        return triggerScanError(`The material in the cyclone is ${cycloneMaterial.name} and the material in the module is ${moduleMaterial.name}.
          Please dock the module at an MHS that is currently loaded with ${moduleMaterial.name}.`);
      } else {
        return triggerScanError(`The material in the cyclone is ${cycloneMaterial.name}. This is not an allowed material type within the selected dose module.
          Please select a new dose module that is able to accept ${cycloneMaterial.name}.`);
      }
    }

    const moduleBatch = moduleHasBatch ? await fetchContainerRelatedBatch(mhsModule.current_batch) : null;

    const hasUnsupportedMaterials = checkSupportedMaterialsMismatch(moduleBatch,
      postProcessorType, false, true);

    if (!_isEmpty(hasUnsupportedMaterials)) {
      const materialWording = pluralWord('material', hasUnsupportedMaterials)
      return triggerScanError(`${postProcessorType.name} does not support ${materialWording}: ${hasUnsupportedMaterials.join(', ')}`)
    }

    // handle the case when you dock module and the location of the module is not
    // the same as the post processor (MHS Machine) location
    const hasDifferentLocations = hasLocationsMismatch(
      mhsModule.location,
      postProcessor.location
    );

    if (hasDifferentLocations) {
      return openActionPanel({
        panelId: 'relocation-panel',
        title: 'Locations Mismatch',
        stack: true,
        content: (
          <LocationsMismatchWarning
            sourceResource={postProcessor}
            destinationResource={mhsModule}
            sourceType="MHS Machine"
            destinationType={MODULE_TYPES_MAP[mhsModule.type]}
            sourceDisplayName={postProcessor.name}
            action="Dock Module"
            bottomTextRenderer={() => (
              <p>Would you like to relocate the module to match the MHS Machine location?</p>
            )}
            onConfirm={() => onConfirmRelocate(action, slotPosition, mhsModule)}
            onCancel={() => onConfirmRelocate(action, slotPosition, mhsModule, true)}
          />
        ),
      });
    }

    try {
      await handleDockModule(action, slotPosition, mhsModule);
    } catch (error) {
      console.error("Error connecting module", error);
      addToast(`Error connecting module: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }, [mlineMhs?.uri, postProcessor, postProcessorType, mhsOutputModule, mhsInputModule, mhsCyclone])

  const handleUndockModule = useCallback(async (action, module, slotPosition) => {
    if (!module) return null;

    try {
      await triggerMhsModuleConnection(
        action,
        mlineMhs.uri,
        slotPosition,
        module.current_batch,
        module.uri
      );
      addToast(`${MHS_MODULES_MAP[slotPosition]} has successfully been undocked`, { appearance: 'success' });
      closeActionPanel();
      await refetchMhsModule();
    } catch (error) {
      console.error("Error undocking module", error);
      addToast(`Error undocking module: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }, [mlineMhs?.uri])

  const handleProcessCycloneLoad = useCallback(async (action, resourceUri, resourceName) => {
    const isInitialResourceBatch = resourceName === API_RESOURCES.MATERIAL_BATCH;
    let scannedContainer = !isInitialResourceBatch ? await fetchContainerOrMhsModuleData(resourceUri) : null;

    if (scannedContainer && !scannedContainer.current_batch) {
      // The scanned module is not a valid MHS module
      return triggerScanError('The scanned container is empty, please scan a container with material');
    }

    const sourceResourceBatch = await fetchContainerRelatedBatch(
      isInitialResourceBatch ? resourceUri : scannedContainer?.current_batch
    );
    const sourceBatchHasMultipleContainers = sourceResourceBatch?.containers?.length > 1;
    const isEmptyBatchLoad = _isEmpty(sourceResourceBatch?.containers);

    const sourceBatchContainers = isInitialResourceBatch && sourceBatchHasMultipleContainers
      ? await fetchBatchValidContainers(sourceResourceBatch)
      : null;

    const batchSourceContainerUri = isInitialResourceBatch ?
      sourceBatchHasMultipleContainers
        ? sourceBatchContainers?.[0]?.uri
        : sourceResourceBatch?.containers[0] : null;

    if (isInitialResourceBatch && !isEmptyBatchLoad && batchSourceContainerUri) {
      scannedContainer = await fetchContainerOrMhsModuleData(batchSourceContainerUri)
    }

    const sourceResource = isEmptyBatchLoad ? sourceResourceBatch : scannedContainer;
    const sourceBatchUri = isInitialResourceBatch ? resourceUri : scannedContainer?.current_batch;
    const invalidBatchStatuses = [MATERIAL_BATCH_STATUSES.DONE, MATERIAL_BATCH_STATUSES.CONSUMED];
    const isMultipleBatchCase = isInitialResourceBatch && sourceBatchHasMultipleContainers;

    if (!sourceResource) return null;

    if (sourceResource.uri === mhsCyclone.uri) {
      // The scanned module is not a valid MHS module
      return triggerScanError('You cannot load the Cyclone with itself');
    }

    if (isEmptyBatchLoad && invalidBatchStatuses.includes(sourceResourceBatch.status)) {
      // The scanned batch status is not valid
      return triggerScanError('The batch you scanned is already completed. Please scan a different batch.');
    }

    if (mhsOutputModule?.uri === sourceResource.uri) {
      // The scanned module is not a valid MHS module
      return triggerScanError(`Module ${mhsOutputModule.name} is already docked to the Sieve Side.`);
    }

    if (mhsInputModule?.uri === sourceResource.uri) {
      // The scanned module is not a valid MHS module
      return triggerScanError(`Module ${mhsInputModule.name} is already docked to the Vacuum Side.`);
    }

    const hasUnsupportedMaterials = checkSupportedMaterialsMismatch(sourceResourceBatch,
      postProcessorType, false, true);

    if (!_isEmpty(hasUnsupportedMaterials)) {
      const materialWording = pluralWord('material', hasUnsupportedMaterials)
      return triggerScanError(`${postProcessorType.name} does not support ${materialWording}: ${hasUnsupportedMaterials.join(', ')}`)
    }

    const isTopOff = action === PERMANENT_CONTAINER_ACTIONS.TOP_OFF || mhsCyclone.current_batch;

    if (isMultipleBatchCase) {
      openActionPanel({
        panelId: 'scan-source-resource',
        title: `Confirm ${!isTopOff ? 'Load' : 'Top Off'} Cyclone`,
        isSidebar: true,
        content: (
          <BatchLoadPanel
            sourceBatchUri={sourceBatchUri}
            destinationResource={mhsCyclone}
            isTopOff={isTopOff}
            destinationResourceBatchUri={mhsCyclone.current_batch}
            destinationResourceTitle="Cyclone"
            actionTitle={isTopOff ? 'Top Off' : 'Load'}
            action={action}
          />
        ),
      });

      return;
    }

    openActionPanel({
      panelId: 'partial-load-panel',
      title: `Confirm ${!isTopOff ? 'Load' : 'Top Off'} Cyclone`,
      content: (<PartialLoadPanel
        hideDestinationResourceTitleHyperlinked
        sourceResource={sourceResource}
        sourceResourceBatchUri={sourceBatchUri}
        destinationResource={mhsCyclone}
        destinationResourceBatchUri={mhsCyclone.current_batch}
        isTopOff={isTopOff}
        action={action}
        isEmptyBatchLoad={isEmptyBatchLoad}
        relocateOptions={{
          resourceToRelocate: sourceResource.uri,
          locationToRelocate: mhsCyclone.location,
          subLocationToRelocate: mhsCyclone.sub_location,
        }}
        actionTitle={isTopOff ? 'Top Off' : 'Load'}
        destinationResourceTitle="Cyclone"
        sourceResourceTitle={isEmptyBatchLoad ? "Batch" : "Container"}
      />),
    });
  }, [mhsCyclone, mhsOutputModule, mhsInputModule])

  const handleSieveToOutput = useCallback(async () => {

    const isTopOff = mhsOutputModule.current_batch;

    openActionPanel({
      panelId: 'partial-cyclone-sieve-panel',
      title: 'Confirm Sieve To Module',
      content: (<PartialLoadPanel
        sourceResource={mhsCyclone}
        sourceResourceBatchUri={mhsCyclone.current_batch}
        destinationResource={mhsOutputModule}
        destinationResourceBatchUri={mhsOutputModule.current_batch}
        isTopOff={isTopOff}
        action={PERMANENT_CONTAINER_ACTIONS.SIEVE}
        isEmptyBatchLoad={false}
        relocateOptions={{
          resourceToRelocate: mhsOutputModule.uri,
          locationToRelocate: mhsCyclone.location,
          subLocationToRelocate: mhsCyclone.sub_location,
        }}
        actionTitle="Sieve"
        destinationResourceTitle="Dose"
        sourceResourceTitle="Cyclone"
        postSubmitAction={refetchOutputModule}
      />),
    });
  }, [mhsCyclone, mhsOutputModule])

  const refetchInputModuleCallback = async () => await refetchInputModule();

  const processBuildModuleLoad = async (reclaimedWeight, consumedWeight) => {
    if (_isEmpty(inputModuleTransactions)) {
      addToast('No transactions found for the Build Module', { appearance: 'error' });
      return
    }

    const buildTransactionBatchActions = await getBuildTransactionsBatchActions(inputModuleTransactions);

    if (_isEmpty(buildTransactionBatchActions)) {
      addToast('No Batch Actions found for the Build Module Transactions', { appearance: 'error' });
      return;
    }

    const consumedBuildBatchAction = buildTransactionBatchActions.find(action => action.metadata.unload_type === 'consumed')
    const reclaimedBuildBatchAction = buildTransactionBatchActions.find(action => action.metadata.unload_type === 'reclaimed')

    // use lodash here to have structure: {<batch_action_uri> = {<transaction>}}
    const buildTransactionsByBatchActionUri = _mapValues(_keyBy(buildTransactionBatchActions, 'uri'), (action) => {
      return inputModuleTransactions.find(transaction => transaction.material_batch_action === action.uri);
    });

    const consumedBuildTransaction = buildTransactionsByBatchActionUri[consumedBuildBatchAction?.uri];
    const reclaimedBuildTransaction = buildTransactionsByBatchActionUri[reclaimedBuildBatchAction?.uri];

    /* eslint-disable camelcase */
    const consumedTransactionPayload = {
      is_complete: true,
      quantity: consumedWeight,
    }

    const reclaimedTransactionPayload = {
      is_complete: true,
      quantity: reclaimedWeight,
    }

    try {
      setActionPanelProps({ isSubmitting: true });
      // Process consumed transaction first
      await api.put(consumedBuildTransaction.uri, {
        prefixUrl: false,
        json: consumedTransactionPayload,
      }).json();
      await api.put(reclaimedBuildTransaction.uri, {
        prefixUrl: false,
        json: reclaimedTransactionPayload,
      }).json();
      const { data: updatedMhsInputModule } = await refetchInputModule();
      const { data: updatedInputModuleBatch } = await refetchInputModuleBatch();
      await refetchInputModuleTransactions();
      closeAllActionPanels();

      if (!updatedInputModuleBatch?.quantity) {
        addToast('You have completed all pending transactions. The Module is now ready to be used again.', { appearance: 'success' });
        return;
      }

      const isTopOff = mhsCyclone.current_batch;
      const action = isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL;

      openActionPanel({
        title: "Load From Vacuum Side",
        content: (
          <PartialLoadPanel
            hideDestinationResourceTitleHyperlinked
            hideSourceResourceTitleHyperlinked={false}
            sourceResource={updatedMhsInputModule}
            sourceResourceBatchUri={updatedMhsInputModule.current_batch}
            destinationResource={mhsCyclone}
            destinationResourceBatchUri={mhsCyclone.current_batch}
            isTopOff={isTopOff}
            action={action}
            isEmptyBatchLoad={false}
            relocateOptions={{
              resourceToRelocate: updatedMhsInputModule.uri,
              locationToRelocate: mhsCyclone.location,
              subLocationToRelocate: mhsCyclone.sub_location,
            }}
            actionTitle={isTopOff ? 'Top Off' : 'Load'}
            destinationResourceTitle="Cyclone"
            sourceResourceTitle="Module"
            postSubmitAction={refetchInputModuleCallback}
          />
        ),
      });

      return;
    } catch (error) {
      console.error("Error updating transaction", error);
      addToast(`Error updating transaction: ${error.message}`, { appearance: 'error' });
      setActionPanelProps({ isError: true, isSubmitting: false });
      throw error;
    }
  }

  const handleConfirmGeneralLoadFromInput = useCallback(async (
    moduleType,
    moduleBatchUri,
    reclaimedWeight,
    consumedWeight
  ) => {
    // for Dose and Overflow

    if (!moduleBatchUri) {
      addToast('No batch found for the module', { appearance: 'error' });
      return;
    }

    const isDoseModule = moduleType === MLINE_MODULE_TYPES.DOSE;
    const isBuildModule = moduleType === MLINE_MODULE_TYPES.BUILD;

    if (isBuildModule) {
      await processBuildModuleLoad(reclaimedWeight, consumedWeight);
      return;
    }

    const currentTransaction = inputModuleTransactions?.[0];

    if (!currentTransaction) {
      addToast(`No transaction found for batch ${getUuid(moduleBatchUri)}`, { appearance: 'error' });
      return;
    }

    if (currentTransaction.is_complete) {
      addToast('Transaction already complete', { appearance: 'error' });
      return;
    }

    const currentTransactionUri = currentTransaction.uri;

    // For Dose module batch.quantity - reclaimedWeight, otherwise reclaimedWeight
    const finalReclaimedWeight = moduleType === MLINE_MODULE_TYPES.DOSE
      ? (inputModuleBatch.quantity - reclaimedWeight) : reclaimedWeight;

    const transactionUpdatePayload = {
      /* eslint-disable camelcase */
      is_complete: true,
      quantity: isDoseModule ? consumedWeight : finalReclaimedWeight,
    }

    try {
      setActionPanelProps({ isSubmitting: true });
      await api.put(currentTransactionUri, {
        prefixUrl: false,
        json: transactionUpdatePayload,
      }).json();
      const { data: updatedMhsInputModule } = await refetchInputModule();
      const { data: updatedInputModuleBatch } = await refetchInputModuleBatch();
      await refetchInputModuleTransactions();
      closeAllActionPanels();

      if (!updatedInputModuleBatch?.quantity) {
        addToast('You have completed all pending transactions. The Module is now ready to be used again.', { appearance: 'success' });
        return;
      }

      const isTopOff = mhsCyclone.current_batch;
      const action = isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL;

      openActionPanel({
        title: "Load From Vacuum Side",
        content: (
          <PartialLoadPanel
            hideDestinationResourceTitleHyperlinked
            hideSourceResourceTitleHyperlinked={false}
            sourceResource={updatedMhsInputModule}
            sourceResourceBatchUri={updatedMhsInputModule.current_batch}
            destinationResource={mhsCyclone}
            destinationResourceBatchUri={mhsCyclone.current_batch}
            isTopOff={isTopOff}
            action={action}
            isEmptyBatchLoad={false}
            relocateOptions={{
              resourceToRelocate: updatedMhsInputModule.uri,
              locationToRelocate: mhsCyclone.location,
              subLocationToRelocate: mhsCyclone.sub_location,
            }}
            actionTitle={isTopOff ? 'Top Off' : 'Load'}
            destinationResourceTitle="Cyclone"
            sourceResourceTitle="Module"
            postSubmitAction={refetchInputModuleCallback}
          />
        ),
      });

      return;
    } catch (error) {
      setActionPanelProps({ isSubmitting: false, isError: true });
      console.error("Error updating transaction", error);
      addToast(`Error updating transaction: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }, [inputModuleBatch?.quantity, inputModuleTransactions, mhsCyclone, mhsInputModule])

  const handleCycloneTrust = useCallback(async (shouldTrustCyclone) => {
    const currentModuleType = mhsInputModule?.type;
    const currentModuleBuild = currentModuleType === MLINE_MODULE_TYPES.BUILD;
    const ComponentToRender = currentModuleBuild ? BuildTrustCycloneForm : GeneralTrustCycloneForm;

    openActionPanel({
      title: 'Confirm Load From Module',
      stack: true,
      content: (
        <ComponentToRender
          module={mhsInputModule}
          trustCyclone={shouldTrustCyclone}
          handleConfirmLoadFromInput={handleConfirmGeneralLoadFromInput}
          expectedWeight="1000"
          slot={MHS_MODULES.INPUT}
        />
      ),
    });
  }, [mhsInputModule, inputModuleTransactions, inputModuleBatch?.quantity, mhsCyclone]);

  const fetchMaterialBatch = async uuid => {
      try {
        // 1. Fetch the main batch data
        const batchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${uuid}/`).json();

        // Prepare the object we'll merge into our state
        let newState = {
          batch: batchData,
        };

        // 2. If batchData exists, fetch other related resources
        if (batchData) {
          const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

          // Prepare parallel fetch calls
          const containerFetchPromise = api
            .get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
              searchParams: {
                'filter[current_batch]': batchData.uri,
                'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
                'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
              },
            })
            .json();

          const subLocationFetchPromise = api
            .get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchData.sub_location)}/`)
            .json();

          // Only fetch printer data if at_machine is present
          const printerFetchPromise = batchData.at_machine
            ? api.get(`${API_RESOURCES.PRINTER}/${getUuid(batchData.at_machine)}/`).json()
            : Promise.resolve(null);

          const postProcessorFetchPromise = batchData.post_processor
            ? api.get(`${API_RESOURCES.POST_PROCESSOR}/${getUuid(batchData.post_processor)}/`).json()
            : Promise.resolve(null);

          // Wait for all requests in parallel
          const [containerFetchResult, subLocationData, loadedPrinterData, loadedPostProcessorData] =
            await Promise.all([
              containerFetchPromise,
              subLocationFetchPromise,
              printerFetchPromise,
              postProcessorFetchPromise,
            ]);

          // Set the derived data in newState
          newState = {
            ...newState,
            allContainers: containerFetchResult.resources,
            containersFetching: false,
            subLocation: subLocationData,
          };

          if (loadedPrinterData) {
            newState.loadedPrinter = loadedPrinterData;
          }

          if (loadedPostProcessorData) {
            newState.loadedPostProcessor = loadedPostProcessorData;
          }
        }

        // 4. Perform one state update
        setBatchState(prevState => ({
          ...prevState,
          ...newState,
        }));
      } catch (e) {
        // If any fetch fails, handle errors and end loading states
        setBatchState(prevState => ({
          ...prevState,
          containersFetching: false,
          containersFetchError: e,
        }));
      }
    };

    const handleTriggerResourceActionPanel = async () => {
      await fetchMaterialBatch(mhsCycloneBatch.uuid);
    };

  useEffect(() => {
    if (batchState.batch) {
      openActionPanel({
        title: "Material Batch",
        content: (
          <BatchCardPreview
            isExpanded
            shouldShowBatchLink
            batch={batchState.batch}
            allContainers={batchState.allContainers}
            containersFetching={batchState.containersFetching}
            containersFetchError={batchState.containersFetchError}
            subLocation={batchState.subLocation?.name}
            loadedPrinter={batchState.loadedPrinter}
            loadedPostProcessor={batchState.loadedPostProcessor}
          />
        ),
      });
    }
  }, [batchState]);

  const handleRequestLoadFromInput = useCallback(async () => {
    const isTopOff = mhsCyclone.current_batch;
    const action = isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL;

    if (_isEmpty(inputModuleTransactions)) {
      // Open a panel directly

      openActionPanel({
        title: "Load From Vacuum Side",
        content: (
          <PartialLoadPanel
            hideDestinationResourceTitleHyperlinked
            hideSourceResourceTitleHyperlinked={false}
            sourceResource={mhsInputModule}
            sourceResourceBatchUri={mhsInputModule.current_batch}
            destinationResource={mhsCyclone}
            destinationResourceBatchUri={mhsCyclone.current_batch}
            isTopOff={isTopOff}
            action={action}
            isEmptyBatchLoad={false}
            relocateOptions={{
              resourceToRelocate: mhsInputModule.uri,
              locationToRelocate: mhsCyclone.location,
              subLocationToRelocate: mhsCyclone.sub_location,
            }}
            actionTitle={isTopOff ? 'Top Off' : 'Load'}
            destinationResourceTitle="Cyclone"
            sourceResourceTitle="Module"
            postSubmitAction={refetchInputModuleCallback}
          />
        ),
      });
      return;
    }

    openActionPanel({
      title: 'Load From Vacuum Side',
      content: (
        <CycloneTrustNotification
          module={mhsInputModule}
          expectedWeight="1000"
          slot={MHS_MODULES.INPUT}
          handleCycloneTrust={handleCycloneTrust}
          inputModuleBatch={inputModuleBatch}
        />),
    });
  }, [inputModuleTransactions, mhsCyclone, mhsInputModule, inputModuleBatch])

  if (isMachineLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (machineError) {
    return (
      <>
        <Header title="MHS Machine" user={user} />
        <main role="main" className="text-center">
          <NotFound id={postProcessorUUID} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="MHS Machine" user={user} />
      <main role="main" className="text-center">
        <MhsCard
          mhsMachine={postProcessor}
          batch={mhsCycloneBatch}
          allowedMaterials={allowedMaterials}
          postProcessorType={postProcessorType}
          loadingState={{
            postProcessorTypeLoading,
            allowedMaterialsLoading,
            cycloneBatchLoading,
          }}
          mhsLocationData={{
            location: mhsLocation,
            subLocation: mhsSubLocation,
            isMhsLocationLoading,
            isMhsSubLocationLoading,
          }}
          handleActionPanel={handleTriggerResourceActionPanel}
        />
        <MHSActions
          modules={mhsModules}
          cyclone={mhsCyclone}
          batch={mhsCycloneBatch}
          handleProcessCycloneLoad={handleProcessCycloneLoad}
          actionPanelProps={{ openActionPanel, closeActionPanel }}
          handleSieveToOutput={handleSieveToOutput}
          handleRequestLoadFromInput={handleRequestLoadFromInput}
          transactionsLoading={transactionsLoading}
          inputModuleTransactions={inputModuleTransactions}
          cycloneLoadingState={{
            cycloneLoading,
            cycloneBatchLoading,
          }}
          mhsLoading={mhsLoading}
        />
        <MHSModulesContainer
          modules={mhsModules}
          handleConnectModule={handleConnectModule}
          handleUndockModule={handleUndockModule}
          actionPanelProps={{ openActionPanel, closeActionPanel }}
          inputModuleTransactions={inputModuleTransactions}
          inputModuleBatch={inputModuleBatch}
          outputModuleBatch={outputModuleBatch}
          modulesLoadingState={{
            isInputModuleLoading,
            isOutputModuleLoading,
            inputModuleBatchLoading,
            outputModuleBatchLoading,
          }}
        />
      </main>
    </>
  );

};

MLineMHSPage.propTypes = {
  user: userPropType,
};

MLineMHSPage.defaultProps = {
  user: null,
};

export default MLineMHSPage;
