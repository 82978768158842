import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Header from "src/components/header";
import Loader from "src/components/loader";
import LocationsMismatchWarning from 'src/components/LocationsMismatchWarning';
import NotFound from "src/components/not-found";
import Tooltip from 'src/components/Tooltip';
import UndockConfirmation from 'src/components/UndockConfirmation';
import { MLINE_MODULE_TYPES, MODULE_TYPES_MAP } from 'src/constants/m-line';
import { useBatch } from 'src/hooks/services/useBatch';
import { useModule } from 'src/hooks/services/useContainer';
import { useLocation, useSubLocation } from 'src/hooks/services/useLocation';
import { useBatchActionTransactionByDoseBatchUri, useMlineLPSByPrinterUri } from 'src/hooks/services/useLps';
import { useMaterialsByUri } from 'src/hooks/services/useMaterials';
import { usePrinter, usePrinterType } from 'src/hooks/services/usePrinter';
import LPSCard from "src/pages/mline/sections/lps/lps-card";
import LPSModulesContainer from "src/pages/mline/sections/lps/modules/lps-module-container";
import RecordBuild from 'src/pages/mline/sections/lps/record-build';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import useBarcodePanelStore from 'src/stores/useBarcodePanelStore';
import useScanStore from 'src/stores/useScanStore';
import {
  handleRecordLpsBuildAction,
  relocateContainerAction,
  triggerLpsModuleConnection,
} from 'src/utils/actionsAPIUtils';
import {
  fetchBatchRelatedTransactions,
  fetchContainerOrMhsModuleData,
  fetchContainerRelatedBatch,
} from 'src/utils/api';
import { MLINE_ACTIONS, PERMANENT_CONTAINER_TYPES_VERBOSE } from 'src/utils/constants';
import {
  checkIfModuleAlreadyDocked,
  checkNotAllowedMaterialsForDoseModule, validateDockingAttempt,
} from 'src/utils/mlineUtils';
import userPropType from "src/utils/user-prop-type";
import { hasLocationsMismatch, isValidCode128 } from 'src/utils/validation';

const MLineLpsPage = ({ user }) => {
  const { uuid: printerUUID } = useParams();

  const didRelocateRef = useRef(false);

  const { addToast } = useToasts();

  const { openActionPanel, closeActionPanel, setActionPanelProps } = useActionPanelStore();
  const { triggerScanError } =
    useScanStore();

  const {
    resetBarcodeValue,
  } = useBarcodePanelStore();

  const {
    data: printer,
    isLoading: isMachineLoading,
    error: machineError,
  } = usePrinter(printerUUID);

  const { data: lpsLocation, isInitialLoading: isLpsLocationLoading } = useLocation(printer?.location, 'lps-location');
  const { data: lpsSubLocation, isInitialLoading: isLpsSubLocationLoading } = useSubLocation(printer?.sub_location, 'lps-sub-location');


  const printerUri = printer?.uri;
  const printerTypeUri = printer?.printer_type;

  const {
    data: printerType,
    isLoading: isPrinterTypeLoading,
  } = usePrinterType(printerTypeUri);

  const allowedMaterialsUris = printerType?.materials;

  const { data: allowedMaterials, isLoading: isMaterialsLoading } = useMaterialsByUri(allowedMaterialsUris);

  const {
    data: mlineLps,
    refetch: refetchLpsModule,
  } = useMlineLPSByPrinterUri(printerUri);

  const doseModuleUri = mlineLps?.dose;
  const buildModuleUri = mlineLps?.build;
  const overflowModuleUri = mlineLps?.overflow;

  const { data: doseModule, isInitialLoading: isDoseModuleLoading } = useModule(doseModuleUri, 'lps-dose-module');
  const { data: buildModule, isInitialLoading: isBuildModuleLoading } = useModule(buildModuleUri, 'lps-build-module');
  const { data: overflowModule, isInitialLoading: isOverflowModuleLoading } = useModule(overflowModuleUri, 'lps-overflow-module');

  const doseBatchUri = doseModule?.current_batch;

  const { data: doseBatch } = useBatch(doseBatchUri, 'lps-dose-batch');

  const { data: batchTransactionForDose, refetch: refetchBatchTransactionsForDose } = useBatchActionTransactionByDoseBatchUri(doseBatchUri, 'batch-transactions-for-dose');

  // If at least Dose is not completed, all modules should be undocked
  const allModulesShouldBeUndocked = !_isEmpty(batchTransactionForDose) && !batchTransactionForDose?.is_complete;

  const LPSModules = [
    { type: MLINE_MODULE_TYPES.DOSE, module: doseModule },
    { type: MLINE_MODULE_TYPES.BUILD, module: buildModule },
    { type: MLINE_MODULE_TYPES.OVERFLOW, module: overflowModule },
  ];

  const unsupportedMaterialsForDose = useMemo(() => {
    return checkNotAllowedMaterialsForDoseModule(doseBatch, buildModule, overflowModule);
  }, [doseBatch, buildModule, overflowModule]);

  const allModulesDocked = LPSModules.every(({ module }) => module);

  const onActionRelocate = async (resourceToRelocate, resourceToRelocateBatchUri) => {
    try {
      const locationToRelocate = printer.location;
      const subLocationToRelocate = printer.sub_location;

      await relocateContainerAction(
        locationToRelocate,
        subLocationToRelocate,
        resourceToRelocate,
        resourceToRelocateBatchUri
      );
      closeActionPanel();
    } catch (error_) {
      console.error(error_);
      throw error_;
    }
  };

  const handleDockModule = async (action, slotPosition, mhsModule) => {
    await triggerLpsModuleConnection(
      action,
      mlineLps.uri,
      slotPosition,
      mhsModule.current_batch,
      mhsModule.uri
    );

    addToast(`${MODULE_TYPES_MAP[slotPosition]} has successfully been docked`, { appearance: 'success' });
    await refetchLpsModule();
    closeActionPanel();
  }

  const onConfirmRelocate = async (action, slotPosition, mhsModule) => {
    try {
      await onActionRelocate(mhsModule.uri, mhsModule.current_batch);
      closeActionPanel();
      // Mark relocation as done immediately
      didRelocateRef.current = true;
      // Now re-run main confirm
      await handleDockModule(action, slotPosition, mhsModule);
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
    }
  };

  const handleConnectModule = useCallback(async (action, moduleUri, slotPosition) => {
    const lpsModule = await fetchContainerOrMhsModuleData(moduleUri);

    if (!lpsModule) {
      return triggerScanError('The scanned module is not a valid LPS module');
    }

    if (!Object.values(MLINE_MODULE_TYPES).includes(lpsModule.type)) {
      // The scanned module is not a valid MHS module
      return triggerScanError(`The scanned module is a '${PERMANENT_CONTAINER_TYPES_VERBOSE[lpsModule.type]}'. You can only dock Dose, Build, or Overflow modules to the LPS machine.`)
    }

    if (
      ((slotPosition === MLINE_MODULE_TYPES.DOSE
      && lpsModule.type === MLINE_MODULE_TYPES.DOSE)
      && !lpsModule.current_batch)
    ) {
      // The scanned module is not a valid LPS module
      return triggerScanError(`The scanned dose module does not contain any material. Please load the module with the required material and then re-dock it to the LPS.`)
    }

    if (lpsModule.type !== slotPosition) {
      const scannedModuleType = MODULE_TYPES_MAP[lpsModule.type];
      const requestedModuleType = MODULE_TYPES_MAP[slotPosition];
      // The scanned module is not a valid LPS module
      return triggerScanError(`The scanned module is not a valid ${requestedModuleType} module. Scanned module type: ${scannedModuleType}`)
    }

    const alreadyDockedMessage = await checkIfModuleAlreadyDocked(lpsModule, "LPS", printer.name);

    if (alreadyDockedMessage) {
      return triggerScanError(alreadyDockedMessage)
    }

    const moduleBatch = lpsModule.current_batch ? await fetchContainerRelatedBatch(lpsModule.current_batch) : {};

    const validationError = validateDockingAttempt({
      newModule: lpsModule,
      newModuleType: slotPosition,
      newDoseModuleBatch: moduleBatch,
      printerType,
      printer,
      doseModule,
      doseBatch,
      buildModule,
      overflowModule,
    });

    if (validationError) {
      return triggerScanError(validationError);
    }

    const moduleTransactions = lpsModule.current_batch ? await fetchBatchRelatedTransactions(lpsModule.current_batch) : {};

    if (!_isEmpty(moduleTransactions)) {
      const hasIncompleteTransactions = moduleTransactions.some(transaction => !transaction.is_complete);

      if (hasIncompleteTransactions) {
        return triggerScanError('The scanned module has incomplete transactions. Please complete all transactions before docking the module.');
      }
    }

    // handle the case when you dock module and the location of the module is not
    // the same as the post processor (LPS Machine) location
    const hasDifferentLocations = hasLocationsMismatch(
        lpsModule.location,
        printer.location
      );

    if (hasDifferentLocations) {
      return openActionPanel({
        panelId: 'relocation-panel',
        title: 'Locations Mismatch',
        stack: true,
        content: (
          <LocationsMismatchWarning
            sourceResource={printer}
            destinationResource={lpsModule}
            sourceType="LPS Machine"
            destinationType={MODULE_TYPES_MAP[lpsModule.type]}
            sourceDisplayName={printer.name}
            action="Dock Module"
            bottomTextRenderer={() => (
              <p>Please relocate the module to match the LPS Machine location.</p>
            )}
            onConfirm={() => onConfirmRelocate(action, slotPosition, lpsModule)}
            onCancel={closeActionPanel}
          />
        ),
      });
    }

    try {
      await handleDockModule(action, slotPosition, lpsModule);
    } catch (error) {
      console.error("Error connecting module", error);
      addToast(`Error connecting module: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }, [mlineLps?.uri, allowedMaterials, buildModule, doseBatch, doseModule, overflowModule, printer, printerType])

  const handleUndockModule = useCallback(async (action, module, slotPosition) => {
    if (!module) return null;

    try {
      await triggerLpsModuleConnection(
        action,
        mlineLps.uri,
        slotPosition,
        module.current_batch,
        module.uri
      );

      addToast(`${MODULE_TYPES_MAP[slotPosition]} has successfully been undocked`, { appearance: 'success' });
      closeActionPanel();
      await refetchLpsModule();
    } catch (error) {
      console.error("Error undocking module", error);
      addToast(`Error undocking module: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }, [mlineLps?.uri])

  const handleUndockAll = async () => {

    if (!allModulesDocked) {
      return addToast('All modules should be docked before undocking', { appearance: 'error' });
    }

    try {
    // Map modules to API requests
    const undockPromises = LPSModules.map(({ type, module }) =>
      triggerLpsModuleConnection(
        MLINE_ACTIONS.UNDOCK_MODULE,
        mlineLps.uri,
        type, // slotPosition (DOSE, BUILD, OVERFLOW)
        module.current_batch,
        module.uri
      )
    );

      // Execute all requests concurrently
      await Promise.all(undockPromises);

      addToast("All modules have successfully been undocked", { appearance: 'success' });
      closeActionPanel();
      await refetchLpsModule();
    } catch (error) {
      console.error("Error undocking modules", error);
      addToast(`Error undocking modules: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  };

  const handleConfirmUndockAll = () => {
    openActionPanel({
      title: 'Undock Confirmation',
      content: <UndockConfirmation
        allModulesUndock
        handleAction={handleUndockAll} />,
    });
  }

  const handleStartBuild = async (barcodeValue) => {
    if ((!_isEmpty(barcodeValue) && !isValidCode128(barcodeValue))) {
      return addToast('Invalid input: External Build ID must follow Code 128 barcode standards.', {
        appearance: 'error',
      });
    }

    if (!doseBatch) return null;

    try {
      setActionPanelProps({ isSubmitting: true });
      await handleRecordLpsBuildAction(doseBatch.uri, printer.uri, barcodeValue);
      addToast('Build has been successfully recorded', { appearance: 'success' });
      await refetchBatchTransactionsForDose();
      closeActionPanel();
      resetBarcodeValue();
    } catch (error) {
      setActionPanelProps({ isSubmitting: false, isError: true });
      console.error("Error starting build", error);
      addToast(`Error starting build: ${error.message}`, { appearance: 'error' });
      throw error;
    }

  }

  const handleRequestBuild = async () => {
      openActionPanel({
        panelId: 'relocation-panel',
        title: 'Record Build',
        stack: true,
        content: (
          <RecordBuild handleStartBuild={handleStartBuild} />
        ),
      });
    }

  const renderStartBuildDisabledTooltip = () => (
    <Tooltip
      id='start-build-disabled-tooltip'
      placement='top'
      trigger={
        <span className="spacer-left">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className='spacer-right'
        />
        </span>
      }
    >
      <div>
        Not all modules are currently docked in the LPS.
        Please confirm all modules are docked successfully before attempting to Start a Build.
      </div>
    </Tooltip>
  )

  if (isMachineLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (machineError) {
    return (
      <>
        <Header title="MHS Machine" user={user} />
        <main role="main" className="text-center">
          <NotFound id={printerUUID} />
        </main>
      </>
    );
  }

  return (
    <>
      <Header title="LPS Machine" user={user} />
      <main role="main" className="text-center">
        <LPSCard
          lpsMachine={printer}
          allowedMaterials={allowedMaterials}
          printerType={printerType}
          isPrinterTypeLoading={isPrinterTypeLoading}
          isMaterialsLoading={isMaterialsLoading}
          lpsLocationData={{
            location: lpsLocation,
            subLocation: lpsSubLocation,
            isLpsLocationLoading,
            isLpsSubLocationLoading,
          }}
        />
        <h5 className="lps-modules">Modules</h5>
        <LPSModulesContainer
          unsupportedMaterialsForDose={unsupportedMaterialsForDose}
          modules={LPSModules}
          handleConnectModule={handleConnectModule}
          handleUndockModule={handleUndockModule}
          allModulesShouldBeUndocked={allModulesShouldBeUndocked}
          modulesLoadingState={{
            isDoseModuleLoading,
            isBuildModuleLoading,
            isOverflowModuleLoading,
          }}
        />
        {allModulesShouldBeUndocked && allModulesDocked && (
          <button
            type="button"
            className="btn btn-lg btn-primary action-btn btn-block mt60"
            onClick={handleConfirmUndockAll}
          >
            <FontAwesomeIcon icon={faArrowDownLong} className="spacer-right" />
            Undock all
          </button>
        )}

        <button
          disabled={
          LPSModules.some(({ module }) => !module)
            || allModulesShouldBeUndocked
            || !_isEmpty(unsupportedMaterialsForDose)
        }
          type="button"
          className="btn btn-lg btn-primary action-btn btn-block mt60"
          onClick={handleRequestBuild}
        >
          <FontAwesomeIcon icon={faPlay} className="spacer-right" />
          Start Build
          {LPSModules.some(({ module }) => !module) && renderStartBuildDisabledTooltip()}
        </button>
      </main>
    </>
  );

};

MLineLpsPage.propTypes = {
  user: userPropType,
};

MLineLpsPage.defaultProps = {
  user: null,
};

export default MLineLpsPage;
