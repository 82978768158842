import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faBars, faSignOut, faSun } from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import FloatingScanButton from 'src/components/FloatingScanButton';
import YBugFeedbackButton from 'src/components/YBugButton';
import { CONTACTLESS_LOGIN_ENABLED, LOCALSTORAGE_KEYS } from 'src/utils/constants';
import routes from 'src/utils/routes';
import useLocalstorage from 'src/utils/useLocalStorage';

import SessionCountdown from './session/session-countdown';

const getAvatarName = name => {
  if (name?.includes(' ')) {
    const [first, second] = name.split(' ');
    return `${first.slice(0, 1)}${second.slice(0, 1)}`;
  }

  return name?.slice(0, 2);
};

const BackButton = React.forwardRef(({ onClick }, ref) => (
  <button
    ref={ref}
    type='button'
    className='btn text-reset text-white'
    aria-label='Back'
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faChevronCircleLeft} />
  </button>
));

BackButton.displayName = 'BackButton';

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: null,
};

const Header = ({ title, back, menu, user, scan, onPrintQRCodesClick }) => {
  const navigate = useNavigate();
  const pageTitle = title.startsWith('Authentise') ? title : `Authentise – ${title}`;
  const [debugMode] = useLocalstorage(LOCALSTORAGE_KEYS.DEBUG_MODE);

  const backLink = typeof back !== 'function';
  const initialLogin = isObject(user) && 'initialLogin' in user;
  const hideBack = back === '' || initialLogin;
  const [cookies] = useCookies([CONTACTLESS_LOGIN_ENABLED]);

  return (
    <header role='banner'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <nav role='navigation' className='navbar navbar-dark bg-primary navbar-expand-sm'>
        <div className='navbar-square-item d-flex justify-content-center align-items-center'>
          {!hideBack && backLink && (
            <Link to={back}>
              <BackButton />
            </Link>
          )}
          {!hideBack && !backLink && <BackButton onClick={() => navigate(-1)} />}
        </div>
        <div className='text-center flex-grow-1'>
          <div className='navbar-text' data-testid='header-title'>
            {title}
          </div>
        </div>
        <div>
          {!menu && user && cookies.contactlessLoginEnabled && <SessionCountdown />}

          <YBugFeedbackButton />

          {!menu && user && (
            <Link to={routes.about}>
              <button type='button' className='btn text-reset text-white' aria-label='About Page'>
                <FontAwesomeIcon icon={faSun} />
              </button>
            </Link>
          )}
        </div>
        <div className='navbar-square-item d-flex justify-content-center align-items-center'>
          {menu && (
            <Link to={menu}>
              <button
                type='button'
                className='btn text-reset text-white'
                aria-label='Recent Activity'
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </Link>
          )}
          {!menu && user && (
            <div className='avatar w-100 h-100 d-flex justify-content-center align-items-center'>
              <span aria-label={`${user.name}`}>{getAvatarName(user.name)}</span>
            </div>
          )}
        </div>
        {debugMode && user && (
          <button
            type='button'
            className='btn text-reset text-white reload-btn'
            aria-label='Reload'
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon icon={faRotateRight} color='#000' />
          </button>
        )}
        {!menu && user && (
          <Link to={routes.logout}>
            <button
              type='button'
              className='btn text-reset text-white logout-btn'
              aria-label='Log out'
            >
              <FontAwesomeIcon icon={faSignOut} color='#000' />
            </button>
          </Link>
        )}
        {!scan && !menu && user && <FloatingScanButton onPrintQRCodesClick={onPrintQRCodesClick} />}
      </nav>
    </header>
  );
};

Header.propTypes = {
  onPrintQRCodesClick: PropTypes.func,
  title: PropTypes.string,
  back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  menu: PropTypes.string,
  scan: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

Header.defaultProps = {
  onPrintQRCodesClick: null,
  title: 'Authentise',
  back: () => null,
  menu: '',
  scan: false,
  user: null,
};

export default Header;
