import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import routes from 'src/utils/routes';

export const ACTION_PROGRESS_RESOURCE_NAMES = {
  [API_RESOURCES.MATERIAL_BATCH]: 'Batch',
  [API_RESOURCES.MATERIAL_CONTAINER]: 'Container',
  [API_RESOURCES.PRINTER]: 'Printer',
};

export const ACTION_PROGRESS_CONFIG = {
  batch: {
    params: ['batch', 'material-batch', 'initialBatch'],
    pattern: /(?:material-)?batch\/([0-9a-fA-F-]{36})/i,
    uuidParams: ['initialBatchUUID', 'batchUUID'],
    specificActions: new Set([]),
    dynamicParams: {},
  },
  printer: {
    params: ['printer'],
    pattern: /printer\/([0-9a-fA-F-]{36})/i,
    uuidParams: ['printerUUID'],
    specificActions: new Set([]),
    dynamicParams: {},
  },
  container: {
    params: ['container', 'permanentContainer'],
    pattern: /(?:permanent-)?container\/([0-9a-fA-F-]{36})/i,
    uuidParams: ['containerUUID'],
    specificActions: new Set([
      PERMANENT_CONTAINER_ACTIONS.SCRAP,
      PERMANENT_CONTAINER_ACTIONS.SIEVE,
    ]),
    dynamicParams: {
      [PERMANENT_CONTAINER_ACTIONS.SIEVE]: {
        add: ['sourceContainerUUID'],
        exclude: [],
      },
      [PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE]: {
        add: [],
        exclude: ['permanentContainer'],
      },
      [PERMANENT_CONTAINER_ACTIONS.SCRAP]: {
        add: [],
        exclude: [],
      },
      [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: {
        add: ['sourceContainerUUID'],
        exclude: [],
      },
    },
  },
};

export const BATCH_ACTIONS_TOTAL_STEPS = {
  /* eslint-disable camelcase */
  [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: { totalSteps: 2 },
  [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: { totalSteps: 3 },
  [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: { totalSteps: 2 },
  [MATERIAL_BATCH_ACTIONS.SIEVE]: { totalSteps: 2 },
  [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_TO_CONTAINER]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.CONTAINER_UNLOAD]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: { totalSteps: 2 },
  [PERMANENT_CONTAINER_ACTIONS.SCRAP]: { totalSteps: 2 },
  [PERMANENT_CONTAINER_ACTIONS.SIEVE]: { totalSteps: 3 },
  [MATERIAL_BATCH_ACTIONS.RELOCATE]: { totalSteps: 2 },
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: { totalSteps: 3 },
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: { totalSteps: 3 },
  [MATERIAL_BATCH_ACTIONS.START_TEST]: { totalSteps: 2 },
  [MATERIAL_BATCH_ACTIONS.TEST]: { totalSteps: 2 },
  [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.MATERIAL_LOAD]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: { totalSteps: 3 },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE]: { totalSteps: 3 },
  [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: { totalSteps: 3 },
  already_loaded: { totalSteps: 3 },
};

const STEP_LABELS = {
  SPECIFY_AMOUNT: 'Specify Amount',
  SCAN_RESOURCE: 'Scan Resource',
  SCAN_BATCH: 'Scan Material Batch',
  COMPLETE: 'Complete',
  SELECT_CONTAINERS: 'Select Containers',
  CONFIRM_LOAD: 'Confirm Load',
  CONFIRM_SCRAP: 'Confirm Scrap',
  CONFIRM_BLENDING: 'Confirm Blending',
  NEW_LOCATION: 'New Location',
  START_TEST: 'Start Test',
  CONFIRM_TOP_OFF: 'Confirm Top Off',
};

export const ACTIONS_STEPS = {
  [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: {
    1: STEP_LABELS.SPECIFY_AMOUNT,
    2: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: {
    1: STEP_LABELS.SCAN_BATCH,
    2: STEP_LABELS.CONFIRM_BLENDING,
    3: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: {
    1: STEP_LABELS.CONFIRM_SCRAP,
    2: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.SIEVE]: {
    1: STEP_LABELS.SPECIFY_AMOUNT,
    2: STEP_LABELS.COMPLETE,
  },

  [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_TO_CONTAINER]: {
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.CONTAINER_UNLOAD]: {
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.SCRAP]: {
    1: STEP_LABELS.CONFIRM_SCRAP,
    2: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },

  [MATERIAL_BATCH_ACTIONS.RELOCATE]: {
    1: STEP_LABELS.NEW_LOCATION,
    2: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: {
    1: STEP_LABELS.NEW_LOCATION,
    2: STEP_LABELS.COMPLETE,
  },

  [MATERIAL_BATCH_ACTIONS.START_TEST]: {
    1: STEP_LABELS.START_TEST,
    2: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.TEST]: {
    2: STEP_LABELS.COMPLETE,
  },

  [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.SIEVE]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.MATERIAL_LOAD]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: {
    2: STEP_LABELS.SELECT_CONTAINERS,
    3: STEP_LABELS.COMPLETE,
  },
  already_loaded: {
    2: STEP_LABELS.CONFIRM_TOP_OFF,
    3: STEP_LABELS.COMPLETE,
  },
  [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
  [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: {
    1: STEP_LABELS.SCAN_RESOURCE,
    2: STEP_LABELS.SPECIFY_AMOUNT,
    3: STEP_LABELS.COMPLETE,
  },
};

export const ALLOWED_ACTION_PROGRESS_ROUTES = [
  routes.scan,
  routes.materialBatchSplitBatch,
  routes.permanentContainerUnloadAction,
  routes.permanentContainerSuccess,
  routes.printerLoadMaterialAction,
  routes.printerLoadMaterialBatchAction,
  routes.printerSuccess,
  routes.materialBatchUnloadReclaimedMaterial,
  routes.permanentContainerAlreadyLoaded,
  routes.permanentContainerActionRelocate,
  routes.permanentContainerActionContainerUnload,
  routes.permanentContainerActionSplit,
  routes.permanentContainerActionSieve,
  routes.permanentContainerScrapAction,
  routes.permanentContainerLoadMaterialBatchAction,
  routes.permanentContainerLoadAction,
  routes.permanentContainerUnloadCyclone,
  routes.materialBatchBlendBatches,
  routes.printerAlreadyLoaded,
  routes.materialBatchMachineUnloadChamber,
  routes.materialBatchMachineUnloadHopper,
  routes.materialBatchScrapBatch,
  routes.materialBatchSieve,
  routes.materialBatchRelocate,
  routes.materialBatchStartTest,
  routes.materialBatchMachineLoad,
  routes.materialBatchSuccess,
];

const pathIncludes = value => ({ type: 'pathIncludes', value });
const queryParamEquals = (key, value) => ({ type: 'queryParamEquals', key, value });
const checkQueryParam = (url, key, value) => new URL(url).searchParams.get(key) === value;

/**
 * A convenience to define one step in the config:
 * { step: number, conditions: Condition[] }
 */
const defineStep = (step, conditions = [], customNextStep) => ({
  step,
  conditions,
  customNextStep,
});

/**
 * Often repeated pattern for success pages:
 * e.g., step: 2 or 3, with conditions:
 * [
 *   { type: 'pathIncludes', value: '/success' },
 *   { type: 'queryParamEquals', key: 'action', value: someAction }
 * ]
 */
const successConditionsForAction = action => [
  pathIncludes('/success'),
  queryParamEquals('action', action),
];

export const ACTION_STEP_CONFIG = {
  /* eslint-disable camelcase */

  // Example of using helpers to remove repetition
  split_batch: [
    defineStep(1, [pathIncludes('/action/split-batch')]),
    defineStep(2, successConditionsForAction('split_batch')),
  ],

  blend_batches: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'blend_batches')]),
    defineStep(2, [pathIncludes('/action/blend-batches')]),
    defineStep(3, successConditionsForAction('blend_batches')),
  ],

  scrap_batch: [
    defineStep(1, [pathIncludes('/action/scrap-batch')]),
    defineStep(2, successConditionsForAction('scrap_batch')),
  ],

  sieve_batch: [
    defineStep(1, [pathIncludes('/action/sieve')]),
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'sieve_batch')]),
    defineStep(1, [
      pathIncludes('/scan'),
      queryParamEquals('action', 'sieve_batch'),
      queryParamEquals('initialSieveAction', 'true'),
    ]),
    // Sieve can succeed in different ways:
    defineStep(2, [pathIncludes('/success'), queryParamEquals('action', 'sieve')]),
    defineStep(3, successConditionsForAction('sieve_batch')),
  ],

  action_unload_to_container: [
    defineStep(2, [
      pathIncludes('/action/action-unload-to-container'),
      queryParamEquals('initialAction', 'unload_unused_material'),
    ]),
    defineStep(2, [
      pathIncludes('/action/action-unload-to-container'),
      queryParamEquals('initialAction', 'unload_reclaimed_material'),
    ]),
    defineStep(2, [
      pathIncludes('/action/action-unload-to-container'),
      queryParamEquals('initialAction', 'sieve_batch'),
    ]),
    defineStep(2, [
      pathIncludes('/action/action-unload-to-container'),
      queryParamEquals('initialAction', 'sieve_batch'),
      queryParamEquals('initialSieveAction', 'true'),
    ]),
    defineStep(3, successConditionsForAction('sieve_batch')),
  ],

  unload_reclaimed_material: [
    defineStep(2, [pathIncludes('/action/unload-reclaimed-material')]),
    defineStep(3, [pathIncludes('/success')]),
  ],

  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE]: [
    defineStep(2, [pathIncludes('/action/unload-cyclone')]),
    defineStep(3, [pathIncludes('/success')]),
  ],

  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: [
    defineStep(2, [pathIncludes('/action/machine-unload-chamber')]),
    defineStep(3, [pathIncludes('/success')]),
  ],

  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: [
    defineStep(2, [pathIncludes('/action/machine-unload-hopper')]),
    defineStep(3, [pathIncludes('/success')]),
  ],

  relocate: [
    defineStep(1, [pathIncludes('/action/relocate')]),
    defineStep(2, successConditionsForAction('relocate')),
  ],

  relocate_container: [
    defineStep(1, [pathIncludes('/action/relocate')]),
    defineStep(2, successConditionsForAction('relocate')),
  ],

  start_test: [defineStep(1, [pathIncludes('/action/start-test')])],

  test: [defineStep(2, successConditionsForAction('test'))],

  machine_load: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'machine_load')], href =>
      checkQueryParam(href, 'batchMultipleContainers', 'true')
        ? STEP_LABELS.SELECT_CONTAINERS
        : null
    ),
    defineStep(2, [
      pathIncludes('/action/machine-load'),
      queryParamEquals('initialBatchAction', 'true'),
    ]),
    defineStep(2, [
      pathIncludes('/action/machine-load'),
      queryParamEquals('initialBatchAction', 'undefined'),
    ]),
    defineStep(3, [pathIncludes('/success'), queryParamEquals('action', 'load_container')]),
  ],

  load_material: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'machine_load')]),
    defineStep(2, [pathIncludes('/action/load-material')]),
    defineStep(3, [pathIncludes('/success'), queryParamEquals('action', 'load_container')]),
  ],

  load_container: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'load_container')]),
    defineStep(2, [
      pathIncludes('/action/load-material'),
      queryParamEquals('action', 'load_container'),
    ]),
    defineStep(3, successConditionsForAction('load_container')),
  ],

  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: [
    defineStep(2, [
      pathIncludes('/action/load-material-batch'),
      queryParamEquals('action', 'load_material_batch'),
    ]),
    defineStep(2, successConditionsForAction('load_container')),
  ],

  [PERMANENT_CONTAINER_ACTIONS.CONTAINER_UNLOAD]: [
    defineStep(2, [
      pathIncludes('/action/action-container-unload'),
      queryParamEquals('initialAction', 'sieve'),
    ]),
    defineStep(3, successConditionsForAction('sieve')),
  ],

  [PERMANENT_CONTAINER_ACTIONS.SCRAP]: [
    defineStep(1, [pathIncludes('/action/scrap-container')]),
    defineStep(2, successConditionsForAction('scrap-container')),
  ],

  already_loaded: [defineStep(2, [pathIncludes('/already-loaded')])],

  top_off_container: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'top_off_container')]),
    defineStep(2, [
      pathIncludes('/action/load-material'),
      queryParamEquals('action', 'top_off_container'),
    ]),
    defineStep(3, successConditionsForAction('top_off_container')),
  ],

  split_container: [
    defineStep(2, [pathIncludes('/action/split')]),
    defineStep(3, successConditionsForAction('split_container')),
  ],

  sieve: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'sieve')]),
    defineStep(2, [pathIncludes('/action/sieve')]),
    defineStep(3, successConditionsForAction('split_container')),
  ],

  machine_top_off: [
    defineStep(1, [pathIncludes('/scan'), queryParamEquals('action', 'machine_top_off')]),
    defineStep(3, [pathIncludes('/success'), queryParamEquals('action', 'machine_top_off')]),
  ],
};
