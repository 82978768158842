window._env_ = window._env_ || process.env;

export default {
  nodeEnv: process.env.NODE_ENV,
  commit: window._env_.COMMIT_HASH,
  version: window._env_.VERSION_ID,
  gitDescribe: window._env_.GITDESCRIBE,
  sentryDsn: window._env_.SENTRY_DSN || window._env_.SENTRY_DSN_QR,
  sentrySampleRate: window._env_.SENTRY_SAMPLE_RATE || 1.0,
  environment: window._env_.ENVIRONMENT,
  apiHost: window._env_.API_HOST,
  yBugId: window._env_.YBUG_ID,
  rapidfabHost: window._env_.RAPIDFAB_HOST,
  test: process.env.NODE_ENV === 'test',
};
