import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ActionContext from 'src/components/action-context';
import Alert from 'src/components/alert.jsx';
import ContainerQuantityInput from 'src/components/ContainerQuantityInput';
import Loader from 'src/components/loader';
import LocationsMismatchWarning from 'src/components/LocationsMismatchWarning';
import { useCyclone } from 'src/hooks/services/useMhs';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import useActionPanelStore from "src/stores/useActionPanelStore";
import { relocateContainerAction } from 'src/utils/actionsAPIUtils';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits, roundToPrecision } from 'src/utils/conversions';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { checkSupportedMaterialsMismatch, hasLocationsMismatch } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import {
  formatUnloadQuantityInMachineNumber,
  renderRemainingValueClass,
} from '../../../material-batch/[uuid]/action/machine-unload-hopper';
import PermanentContainerActionPageWrapper from './_action-wrapper';

const UnloadCyclone = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: permanentContainerUUID } = useParams();
  const {
    batch: batchUri,
    permanentContainer,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const {
    data: cyclone,
  } = useCyclone(permanentContainer);

  const { openActionPanel, closeActionPanel, closeAllActionPanels } = useActionPanelStore();
  const { addToast } = useToasts();

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [hasNoCapacityForMultipleContainers, setHasNoCapacityForMultipleContainers] = useState(false);
  const [isFinishContainerActionDisabled, setIsFinishContainerActionDisabled] = useState(true);
  const [isAnyContainerLoaded, setIsAnyContainerLoaded] = useState(false);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);
  const [initialQuantity, setInitialQuantity] = useState(undefined);
  const navigate = useNavigate();

  const { units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch?.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);

  const convertedRemainingQuantity = useMemo(() => {
    return isConverted
      ? convertToUserUnits(remainingQuantity, batch?.units).quantity
      : remainingQuantity;
  }, [remainingQuantity, isConverted, batch?.units]);

  const {
    containerActionData,
  } = useContext(ActionContext);
  const { permanentContainersActionState } = containerActionData;

  const unsupportedMaterials = useMemo(() => {
    return Object.values(permanentContainersActionState.containersScanned).flatMap(container => {
      return checkSupportedMaterialsMismatch(batch, container, true);
    });
  }, [batch, permanentContainersActionState.containersScanned]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUUID}/`).json();
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(batchUri)}/`).json();
      setBatch(batch);
      setInitialQuantity(batch?.quantity);
      const { quantity: convertedInitialBatchQuantity } = convertToUserUnits(batch.quantity, batch.units);

      const currentInitialQuantity = convertedInitialBatchQuantity;
      setRemainingQuantity(currentInitialQuantity);

      let totalAllocatedQuantity = Object.values(permanentContainersActionState.containersScanned)
        .reduce((total, container) => total + container.quantity, 0);

      // Calculate the maxQuantity for the new container
      let newContainerMaxQuantity = currentInitialQuantity - totalAllocatedQuantity;

      // Add updated container to context
      const updatedContainer = {
        ...permanentContainer,
        quantity: 0,
        maxQuantity: newContainerMaxQuantity,
      };
      containerActionData.addScannedContainer(updatedContainer);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    return void getInitialData();
  }, [permanentContainerUUID, batchUri]);

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_CONTAINER_ACTION}/`, {
      json: payload,
    });
  };

  const handleMultiUnload = async (action, source, metadata) => {
    const payload = {
      /* eslint-disable camelcase */
      source_batch: batchUri,
      action_type: action,
      source_material_container: source,
      metadata,
    };
    setSubmitting(true);
    return await materialActionRequest(payload).json();
  };

  const handleSingleUnload = async (action, source, metadata) => {
    const payload = {
      /* eslint-disable camelcase */
      source_batch: batchUri,
      action_type: action,
      source_material_container: source,
      metadata,
    };
    setSubmitting(true);
    return await materialActionRequest(payload).json()
  };

  // add switch will all cases and what to load.
  const onActionFinish = async (value) => {
    let resultingBatch = null;

    const containers = Object.values(permanentContainersActionState.containersScanned);
    const isSingleContainer = containers.length === 1;

    const quantityToUnload = containers.reduce((sum, container) => sum + container.quantity, 0);
    const isQuantityUnloadingFull = quantityToUnload === initialQuantity;

    try {
      if (isSingleContainer && isQuantityUnloadingFull && isAnyContainerLoaded) {
        // single, full unload, container loaded
        resultingBatch = await handleSingleUnload(
          PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
          containers[0].uri,
        );
      } else if (isSingleContainer && isQuantityUnloadingFull && !isAnyContainerLoaded) {
        // single, full unload, container not loaded
        resultingBatch = await handleSingleUnload(
          PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
          containers[0].uri,
        );
      } else if (isSingleContainer && !isQuantityUnloadingFull) {
        // single, part unload
        resultingBatch = await handleSingleUnload(
          PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
          cyclone.uri,
          {
            destination_container: containers[0].uri,
            quantity: quantityToUnload,
          }
        );
      } else if (!isSingleContainer) {
        // Multi container unload
        if (isQuantityUnloadingFull) {
          resultingBatch = await handleMultiUnload(
            PERMANENT_CONTAINER_ACTIONS.UNLOAD_CONTAINER,
            cyclone.uri,
            {
              unload_amount: quantityToUnload,
            }
          );
        } else {
          resultingBatch = await handleMultiUnload(
            PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
            cyclone.uri,
            {
              quantity: quantityToUnload,
            }
          );
        }

        const containersWithUriAndQuantity = containers.map((container) => {
          return {
            uri: container.uri,
            quantity: container.quantity,
          };
        });

        const containerizePayload = {
          action_type: MATERIAL_BATCH_ACTIONS.CONTAINERIZE_BATCH,
          source_batch: isQuantityUnloadingFull ? resultingBatch.source_batch : resultingBatch.metadata.created_material_batch,
          metadata: {
            containers: containersWithUriAndQuantity,
          },
        };

        await api.post('material-batch-action/', {
          json: containerizePayload,
        });
      }
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: permanentContainerUUID },
      {
        containerAction: true,
        sourcePermanentContainer: cyclone.uuid,
        action: PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE,
        batch: getUuid(resultingBatch.source_batch),
        quantity: quantityToUnload,
        remainingQuantity: value,
        ...({ sourceContainerUUID: getUuid(cyclone.uri) }),
      }));

    return new Promise(() => {});
  };

  const onActionScanAdditionalContainer = async (batchUri) => {
    navigate(getRouteURI(routes.scan,
      {},
      {
        permanentContainer: cyclone.uri,
        'material-batch': batchUri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.UNLOAD_CYCLONE,
        isSameScan: true,
      }
    ));

    return new Promise(() => {});
  };

  const onActionRelocate = async (resourceToRelocate, resourceToRelocateBatchUri) => {
    try {
      const locationToRelocate = cyclone.location;
      const subLocationToRelocate = cyclone.sub_location;

      await relocateContainerAction(
        locationToRelocate,
        subLocationToRelocate,
        resourceToRelocate,
        resourceToRelocateBatchUri
      );

      removeContainerHandler(resourceToRelocate);
      getInitialData();
      closeActionPanel();
    } catch (error_) {
      console.error(error_);
      throw error_;
    }
  };

  const onConfirmRelocate = async (container) => {
    try {
      await onActionRelocate(container.uri, container.current_batch);
      closeAllActionPanels();
      addToast(`Container successful relocated.`, { appearance: 'success' });
    } catch (error) {
      addToast(`${error}`, { appearance: 'error' });
      closeActionPanel();
    }
  };

  useEffect(() => {
    const containers = Object.values(permanentContainersActionState.containersScanned);
    const lastAddedContainer = containers[containers.length - 1];
    const totalQuantityUsed = containers.reduce((sum, container) => sum + container.quantity, 0);

    const calculatedRemainingQuantity = roundToPrecision(
      initialQuantity - totalQuantityUsed
    );
    setRemainingQuantity(calculatedRemainingQuantity);

    // if container location does not match cyclone location
    const hasDifferentLocations = hasLocationsMismatch(
      cyclone?.location,
      lastAddedContainer?.location
    );

    // Is container loaded
    const isContainerLoaded = containers.some((container) => container.current_batch);
    setIsAnyContainerLoaded(isContainerLoaded);

    // If there's only one container and its quantity is the full batch quantity,
    // or if all containers have a quantity set, the finish unload should be enabled.
    const isAnyContainerEmpty = containers.some((container) => container.quantity === 0);
    const isOnlyOneContainerFull = containers.length === 1 && totalQuantityUsed === initialQuantity;
    setIsFinishContainerActionDisabled(isAnyContainerEmpty && !isOnlyOneContainerFull);

    // Determine if we have capacity for another container
    const predictedRemainingQuantity = initialQuantity - totalQuantityUsed;
    const hasCapacityForAnotherContainer = predictedRemainingQuantity > 0;
    setHasNoCapacityForMultipleContainers(!hasCapacityForAnotherContainer);

    if (hasDifferentLocations && lastAddedContainer) {
      return openActionPanel({
        panelId: 'relocation-panel',
        title: 'Locations Mismatch',
        stack: true,
        content: (
          <LocationsMismatchWarning
            sourceResource={cyclone}
            destinationResource={lastAddedContainer}
            sourceType="Cyclone"
            sourceDisplayName={cyclone?.name}
            action="Unload Cyclone"
            bottomTextRenderer={() => (
              <p>Would you like to relocate the module to match the MHS Machine location?</p>
            )}
            onConfirm={() => onConfirmRelocate(lastAddedContainer)}
            onCancel={() => {
              removeContainerHandler(lastAddedContainer.uri);
              closeAllActionPanels();
              containers.length === 1 && navigate(getRouteURI(routes.postProcessorDetails, { uuid: getUuid(cyclone?.workstation_uri) }))
            }}
          />
        ),
      });
    }
  }, [JSON.stringify(permanentContainersActionState.containersScanned), initialQuantity]);

  const setRemainingQuantityHandler = (containerUri, baseQuantity) => {
    const containerUuid = getUuid(containerUri);
    const { containersScanned } = permanentContainersActionState;

    if (containersScanned[containerUuid]) {
      containersScanned[containerUuid].quantity = baseQuantity;
    }

    let totalScannedQuantity = Object.values(containersScanned)
      .reduce((total, container) => total + container.quantity, 0);

    // Update maxQuantity for each container
    Object.keys(containersScanned).forEach((uuid) => {
      const remainingQty =
        initialQuantity - totalScannedQuantity + containersScanned[uuid].quantity;
      containersScanned[uuid].maxQuantity = Math.min(remainingQty, initialQuantity);
    });

    // Update the remaining quantity
    setRemainingQuantity(parseFloat((initialQuantity - totalScannedQuantity).toFixed(3)));
    containerActionData.updateContainerQuantities(containersScanned);
  };

  const removeContainerHandler = (containerUri) => {
    containerActionData.removeScannedContainer(containerUri);
  };

  const resetActionContainerState = () => {
    // Reset the context state to its initial state
    containerActionData.resetActionContainerState();
  };

  return (
    <PermanentContainerActionPageWrapper
      id={permanentContainerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL}
      isLoading={isLoading}
    >
      <div className="d-flex flex-column align-items-center justify-content-center alert alert-info" role="alert">
        <b>
          Please specify the amount you want to unload from {cyclone?.name}.
        </b>
      </div>

      { isAnyContainerLoaded && Object.values(permanentContainersActionState.containersScanned).length > 1 && (
        <Alert variant="danger" name='containerNotEmpty'>
          One of the scanned containers is loaded. You can only top-off a single container at a time. Please remove the other containers to continue.
        </Alert>
      )}

      { isAnyContainerLoaded && Object.values(permanentContainersActionState.containersScanned).length === 1 && (
        <Alert variant="warning" name='containerNotEmpty'>
          The container you have added is loaded. You can not load another container.
        </Alert>
      )}

      <p className="font-bold">Total Quantity in Cyclone:&nbsp;
        <span className={renderRemainingValueClass(remainingQuantity)}>
          {formatUnloadQuantityInMachineNumber(remainingQuantity)} ({batch?.units})&nbsp;
        </span>
        <span>{isConverted && formatConvertedUnits(convertedRemainingQuantity, convertedBatchUnits)}</span>
      </p>

      <hr className="flex-grow-1" />

      {
        Object.values(permanentContainersActionState.containersScanned).map((container) => {
          const containersLength = Object.values(permanentContainersActionState.containersScanned).length;
          const isNotSupportedByMaterials = unsupportedMaterials.find((unsupportedContainer) => unsupportedContainer.uri === container.uri);
          return (
            <div key={container.uri}>
              <ContainerQuantityInput
                container={container}
                batchUnits={batch?.units}
                convertedBatchUnits={convertedBatchUnits}
                initialQuantity={initialQuantity}
                isConverted={isConverted}
                setRemainingQuantityHandler={setRemainingQuantityHandler}
                removeContainerHandler={removeContainerHandler}
                containersLength={containersLength}
                isNotSupportedByMaterials={isNotSupportedByMaterials}
              />
            </div>
          );
        })
      }
      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={
          isSubmitting ||
          isFinishContainerActionDisabled ||
          (remainingQuantity === initialQuantity || remainingQuantity < 0) ||
          unsupportedMaterials.length ||
          isAnyContainerLoaded && Object.values(permanentContainersActionState.containersScanned).length > 1
        }
        onClick={() => onActionFinish(remainingQuantity)}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Finish Unload
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          {unsupportedMaterials.length ? (
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="workstation-is-overloaded">
                  {
                    unsupportedMaterials.map((container) => (
                      <p key={container.name} className="mb5 mt5">
                        Permanent Container &#39;{container.name}&#39; does not support&nbsp;
                        {unsupportedMaterials.length > 1 ? 'materials' : 'material'}&nbsp;
                        {container.materialsNotSupported.map((material) => material).join(', ')}
                      </p>

                    ))
                  }
                </Tooltip>)}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
            </OverlayTrigger>
          ) : null}
        </p>
      </button>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || hasNoCapacityForMultipleContainers || isAnyContainerLoaded}
        onClick={() => onActionScanAdditionalContainer(batch.uri)}
      >
        Load Another Container
      </button>

      <Link to={getRouteURI(routes.postProcessorDetails, { uuid: getUuid(cyclone?.workstation_uri) })}>
        <button
          type="button" disabled={isSubmitting} className="btn btn-default btn-action"
          onClick={resetActionContainerState}
        >
          Cancel
        </button>
      </Link>
    </PermanentContainerActionPageWrapper>
  );
};

UnloadCyclone.propTypes = {
  user: userPropType,
};

UnloadCyclone.defaultProps = {
  user: null,
};

export default UnloadCyclone;
