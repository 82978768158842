import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { MLINE_MODULE_TYPES } from 'src/constants/m-line';
import { fetchModuleWorkstation } from 'src/utils/api';
import { API_RESOURCES } from 'src/utils/constants';

export const isModuleEmpty = (modules = [], type) => {
  if (_isEmpty(modules)) {
    return true;
  }
  return modules.some(({ slot, module }) => slot === type && !module);
};

export const renderDynamicContent = (
  isLoading,
  content,
  loadingComponent = <Spinner animation='border' size='sm' />,
  fallback = null
) => {
  if (isLoading) {
    return loadingComponent;
  }
  if (!content) return fallback;

  return content;
};

/**
 * Checks for unsupported materials for the build and overflow modules based on the dose batch.
 *
 * For each module (build and overflow), this function compares the dose batch's materials with the module's
 * allowed materials (provided in `material_restrictions`). If the module has restrictions (i.e. its
 * `material_restrictions` array is non-empty), the function filters out dose batch materials whose URI is not
 * included in the allowed list. It returns an object mapping module types (e.g., BUILD, OVERFLOW) to an array
 * of unsupported material names.
 *
 * For example, if:
 *  - doseBatch.materials is:
 *      [ { name: "Material 1", uri: "uri1" }, { name: "Material 2", uri: "uri2" } ]
 *  - buildModule.material_restrictions is [] (which means all materials are allowed)
 *  - overflowModule.material_restrictions is [ "uri1", "uri3" ]
 *
 * then the result will be:
 *    {
 *      [MLINE_MODULE_TYPES.OVERFLOW]: ["Material 2"]
 *    }
 *
 * @param {Object} doseBatch - The dose batch object, expected to contain a `materials` array.
 * @param {Object} buildModule - The build module object, expected to contain a `material_restrictions` array.
 * @param {Object} overflowModule - The overflow module object, expected to contain a `material_restrictions` array.
 * @returns {Object} An object mapping module types (e.g., BUILD, OVERFLOW) to an array of unsupported material names.
 */
export const checkNotAllowedMaterialsForDoseModule = (doseBatch, buildModule, overflowModule) => {
  // Return an empty object if any required data is missing.
  if (!doseBatch || !buildModule || !overflowModule) return {};

  // Extract the dose batch materials, defaulting to an empty array if not provided.
  const doseMaterials = doseBatch.materials ?? [];

  // Retrieve allowed materials for the build and overflow modules.
  // An empty array means the module allows all materials.
  const buildAllowedMaterials = buildModule.material_restrictions ?? [];
  const overflowAllowedMaterials = overflowModule.material_restrictions ?? [];

  // For the build module:
  // If there are restrictions, filter out any dose material whose URI is not in the allowed list,
  // and then map to the material's name.
  const buildUnsupportedMaterials = !_isEmpty(buildAllowedMaterials)
    ? doseMaterials
        .filter(material => !buildAllowedMaterials.includes(material.uri))
        .map(material => material.name)
    : [];

  // For the overflow module:
  // Similarly, if restrictions are present, filter and map the unsupported materials.
  const overflowUnsupportedMaterials = !_isEmpty(overflowAllowedMaterials)
    ? doseMaterials
        .filter(material => !overflowAllowedMaterials.includes(material.uri))
        .map(material => material.name)
    : [];

  // Build the result object mapping module types to the names of unsupported materials.
  const unsupportedMaterials = {};
  if (!_isEmpty(buildUnsupportedMaterials)) {
    unsupportedMaterials[MLINE_MODULE_TYPES.BUILD] = buildUnsupportedMaterials;
  }
  if (!_isEmpty(overflowUnsupportedMaterials)) {
    unsupportedMaterials[MLINE_MODULE_TYPES.OVERFLOW] = overflowUnsupportedMaterials;
  }

  return unsupportedMaterials;
};

export const getMachineTypeFromUrl = urlString => {
  try {
    const url = new URL(urlString);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    return pathSegments[0]; // 'printer' or 'post-processor'
  } catch (error) {
    console.error('Invalid URL', error);
    return null;
  }
};

export const checkIfModuleAlreadyDocked = async (
  module,
  currentMachineType,
  currentMachineName
) => {
  if (!module || !currentMachineType || !currentMachineName) return null;
  const isModuleAlreadyDocked = !!module.workstation_uri && module.is_docked;
  const machineModuleAlreadyDockedTo = isModuleAlreadyDocked
    ? await fetchModuleWorkstation(module.workstation_uri)
    : null;

  if (!_isEmpty(machineModuleAlreadyDockedTo)) {
    const machineName = getMachineTypeFromUrl(module.workstation_uri);
    const machineType = machineName === API_RESOURCES.PRINTER ? 'LPS' : 'MHS';
    return `This module is currently docked at ${machineType} ${machineModuleAlreadyDockedTo.name}.
      Please undock the module before docking it to ${currentMachineType} ${currentMachineName}.`;
  }

  return null;
};

/**
 * Creates a map from material URIs to names.
 * @param {Array<{ uri: string, name: string }>} [materials=[]] - Array of material objects.
 * @returns {Record<string, string>} A map of URI to material name.
 */
export function getMaterialNameMap(materials = []) {
  const map = Object.fromEntries(
    materials.map(({ uri, name }) => [uri, name || 'Unknown material'])
  );
  return map;
}

/**
 * Checks if two arrays have at least one common element.
 * @param {string[]} firstArray - First array of URIs.
 * @param {string[]} secondArray - Second array of URIs.
 * @returns {boolean} True if there’s an intersection, false otherwise.
 */
const hasAtLeastOneCommonMaterial = (firstArray, secondArray) => {
  const set = new Set(secondArray);
  return firstArray.some(item => set.has(item));
};

/**
 * Returns URIs from actualUris that are not in allowedUris, optimized with Sets.
 * @param {string[]} [actualUris=[]] - Array of URIs to check.
 * @param {string[]} [allowedUris=[]] - Array of allowed URIs.
 * @returns {string[]} Array of URIs not allowed.
 */
const getNotAllowedUris = (actualUris = [], allowedUris = []) => {
  const allowedSet = new Set(allowedUris);
  return actualUris.filter(uri => !allowedSet.has(uri));
};

/**
 * Checks if new Dose materials are compatible with a docked module’s restrictions.
 * @param {string[]} doseMaterialUris - New Dose material URIs.
 * @param {{ material_restrictions?: string[], type: string }} module - Docked module (Build or Overflow).
 * @returns {string[]} - Failing uris.
 */
const checkDoseCompatibilityWithModule = (doseMaterialUris, module) => {
  const restrictions = module.material_restrictions ?? [];
  if (restrictions.length > 0 && !hasAtLeastOneCommonMaterial(doseMaterialUris, restrictions)) {
    return getNotAllowedUris(doseMaterialUris, restrictions);
  }
  return [];
};

/**
 * Validates docking a new module in a 3D printing system, ensuring material compatibility.
 * @param {Object} params - Parameters for validation.
 * @param {Object} params.newModule - The module to dock.
 * @param {string} params.newModuleType - Expected type ("DOSE", "BUILD", "OVERFLOW").
 * @param {Object} [params.newDoseModuleBatch] - Batch for a new Dose module.
 * @param {Object} [params.printerType] - Printer type with supported materials.
 * @param {Object} [params.printer] - Printer.
 * @param {Object} [params.doseModule] - Docked Dose module.
 * @param {Object} [params.doseBatch] - Batch for the docked Dose module.
 * @param {Object} [params.buildModule] - Docked Build module.
 * @param {Object} [params.overflowModule] - Docked Overflow module.
 * @returns {string|null} Error message if invalid, null if valid.
 */
export const validateDockingAttempt = ({
  newModule,
  newModuleType,
  newDoseModuleBatch,
  printerType,
  printer,
  doseModule,
  doseBatch,
  buildModule,
  overflowModule,
}) => {
  const lpsAllowedMaterialUris = printerType?.materials ?? [];

  const newDoseMaterials =
    newModuleType === MLINE_MODULE_TYPES.DOSE ? newDoseModuleBatch?.materials ?? [] : [];
  const newDoseMaterialUris = newDoseMaterials.map(m => m.uri);
  const newDoseMaterialNamesMap = getMaterialNameMap(newDoseMaterials);

  const newModuleRestrictions = [MLINE_MODULE_TYPES.BUILD, MLINE_MODULE_TYPES.OVERFLOW].includes(
    newModuleType
  )
    ? newModule.material_restrictions ?? []
    : [];

  const existingDoseMaterials = doseModule && doseBatch ? doseBatch.materials ?? [] : [];
  const existingDoseMaterialUris = existingDoseMaterials.map(m => m.uri);
  const existingDoseMaterialNamesMap = getMaterialNameMap(existingDoseMaterials);

  const isDoseDocked = !!doseModule;
  const isBuildDocked = !!buildModule;
  const isOverflowDocked = !!overflowModule;
  const noModulesDocked = !isDoseDocked && !isBuildDocked && !isOverflowDocked;

  // --- Case 1: No Modules Docked ---
  if (noModulesDocked) {
    if (newModuleType === MLINE_MODULE_TYPES.DOSE) {
      // Check if the new Dose materials (Dose Batch Materials) are supported by the LPS.
      const notAllowedMaterialUris = getNotAllowedUris(newDoseMaterialUris, lpsAllowedMaterialUris);
      if (notAllowedMaterialUris.length) {
        const materialName =
          newDoseMaterialNamesMap[notAllowedMaterialUris[0]] || notAllowedMaterialUris[0];
        return `${materialName} is loaded in the module but is not able to be processed by the printer ${printer.name}. Please check and choose an appropriate module to dock.`;
      }
      return null;
    }
    // Check if the new module’s restrictions are compatible with the LPS allowed materials.
    if (
      newModuleRestrictions.length &&
      !hasAtLeastOneCommonMaterial(newModuleRestrictions, lpsAllowedMaterialUris)
    ) {
      return `The materials allowed within the printer ${printer.name} are not compatible with the module selected. Please check and choose an appropriate module to dock.`;
    }
    return null;
  }

  // --- Case 2: Docking Dose with Build/Overflow Docked ---
  if (!isDoseDocked && newModuleType === MLINE_MODULE_TYPES.DOSE) {
    // Check if the new Dose materials are supported by the LPS.
    const notAllowedMaterialUris = getNotAllowedUris(newDoseMaterialUris, lpsAllowedMaterialUris);
    if (notAllowedMaterialUris.length) {
      const materialName =
        newDoseMaterialNamesMap[notAllowedMaterialUris[0]] || notAllowedMaterialUris[0];
      return `${materialName} is loaded in the module but is not able to be processed by the printer ${printer.name}. Please check and choose an appropriate module to dock.`;
    }

    if (isBuildDocked) {
      // Check if the new Dose materials are compatible with the docked Build module’s restrictions.
      const failingUris = checkDoseCompatibilityWithModule(newDoseMaterialUris, buildModule);
      if (failingUris.length) {
        const materialName = newDoseMaterialNamesMap[failingUris[0]] || failingUris[0];
        return `${materialName} is loaded in the module, but is not allowed in the module that is already docked at printer ${printer.name}. Please check and choose an appropriate module to dock.`;
      }
    }
    if (isOverflowDocked) {
      // Check if the new Dose materials are compatible with the docked Overflow module’s restrictions.
      const failingUris = checkDoseCompatibilityWithModule(newDoseMaterialUris, overflowModule);
      if (failingUris.length) {
        const materialName = newDoseMaterialNamesMap[failingUris[0]] || failingUris[0];
        return `${materialName} is loaded in the module, but is not allowed in the module that is already docked at printer ${printer.name}. Please check and choose an appropriate module to dock.`;
      }
    }
    return null;
  }

  // --- Case 3: Dose Docked, Docking Build or Overflow ---
  if (
    isDoseDocked &&
    [MLINE_MODULE_TYPES.BUILD, MLINE_MODULE_TYPES.OVERFLOW].includes(newModuleType) &&
    newModuleRestrictions.length
  ) {
    // Check if the new module’s restrictions are compatible with the existing Dose Batch materials.
    const notAllowedDoseMaterialUris = getNotAllowedUris(
      existingDoseMaterialUris,
      newModuleRestrictions
    );
    if (notAllowedDoseMaterialUris.length) {
      const materialName =
        existingDoseMaterialNamesMap[notAllowedDoseMaterialUris[0]] ||
        notAllowedDoseMaterialUris[0];
      return `${materialName} is loaded in the module, but is not allowed in the module that is already docked at printer ${printer?.name}. Please check and choose an appropriate module to dock.`;
    }
    return null;
  }

  // --- Case 4: Docking Build or Overflow with Other Modules ---
  if ([MLINE_MODULE_TYPES.BUILD, MLINE_MODULE_TYPES.OVERFLOW].includes(newModuleType)) {
    if (newModuleRestrictions.length) {
      if (!hasAtLeastOneCommonMaterial(newModuleRestrictions, lpsAllowedMaterialUris))
        return `The materials allowed within the printer ${printer.name} are not compatible with the module selected. Please check and choose an appropriate module to dock.`;

      const otherModule =
        newModuleType === MLINE_MODULE_TYPES.BUILD && isOverflowDocked
          ? overflowModule
          : newModuleType === MLINE_MODULE_TYPES.OVERFLOW && isBuildDocked
            ? buildModule
            : null;
      if (otherModule) {
        const otherRestrictions = otherModule.material_restrictions ?? [];
        if (
          otherRestrictions.length &&
          !hasAtLeastOneCommonMaterial(newModuleRestrictions, otherRestrictions)
        )
          return 'The permitted materials allowed in this module do not match those allowed in the docked module. Please check and choose an appropriate module to dock.';
      }
    }
    return null;
  }

  return null;
};
