import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

const YBugContext = createContext(null);

export const useYBugApi = () => useContext(YBugContext);

export const YBugProvider = ({ yBugId, url, settings = {}, children }) => {
  const [yBugApi, setYBugApi] = useState(null);

  useEffect(() => {
    if (yBugId) {
      // Set global Ybug settings
      /* eslint-disable camelcase */
      window.ybug_settings = {
        ...settings,
        id: yBugId,
        onload: () => {
          setYBugApi(window.Ybug);
          if (settings.onload) {
            settings.onload();
          }
        },
      };

      // Create and append the Ybug script
      const script = document.createElement('script');
      script.defer = true;
      script.src = url || `https://widget.ybug.io/button/${yBugId}.js`;
      if (settings?.nonce) {
        script.nonce = settings.nonce; // Optional: Add nonce for CSP
      }
      document.head.append(script);

      return () => {
        // Cleanup function to remove the script and settings
        if (yBugApi) {
          yBugApi.destroy();
        }
        script.remove();
        delete window.ybug_settings;
      };
    }

    return () => {};
  }, [yBugId, settings]);

  return <YBugContext.Provider value={yBugApi}>{children}</YBugContext.Provider>;
};

YBugProvider.defaultProps = {
  settings: {},
  url: null,
};

YBugProvider.propTypes = {
  yBugId: PropTypes.string.isRequired,
  url: PropTypes.string,
  settings: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
