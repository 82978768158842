import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useYBugApi } from 'src/context/YBugContext';
import Config from 'src/utils/config';

const YBugFeedbackButton = () => {
  const YbugApi = useYBugApi();

  if (!YbugApi || !Config.yBugId) {
    // Hide the feedback button if Ybug is not loaded or we need to skip it.
    return null;
  }

  const openYbugReport = () => {
    YbugApi.open('feedback');
  };

  return (
    <button type='button' className='btn text-reset text-white' aria-label='Feedback'>
      <FontAwesomeIcon
        id='feedback-icon-btn'
        role='button'
        icon={faLightbulb}
        title='Feedback'
        onClick={openYbugReport}
      />
    </button>
  );
};

export default YBugFeedbackButton;
